//@ts-ignore
import resources from "./resources.ts";

export default class resourcesEs extends resources {
    public login: string = "ES Login";
    public logout: string = "ES Logout";
    public imprint: string = "ES Imprint";
    public dataPrivacy: string = "ES Data Privacy";
    public contactUsTitle: string = "ES Contact Us";
    public termsConditionSale: string = "ES Terms and Conditions of Sale";
    public copyright: string = "ES 2024 Festo Didactic SE. All rights reserved.";
    public contactUs: string = "ES Contact us";
    public howCanHelp: string = "ES How can we help you?";
    public name: string = "ES Name*:";
    public country: string = "ES Country*:";
    public message: string = "ES Message*:";
    public submit: string = "ES Submit";
    public mandatoryInfos: string = "ES Fields with asterisk (*) are mandatory.";
    public successEmail: string = "ES Your email has been successfuly sent!";
    public feedbackAppreciation: string = "ES We appreciate your feedback! Click on the following button to get back where you left.";
    public return: string = "ES Return";
    public email: string = "ES Email*:";
    public username: string = "ES Username";
    public password: string = "ES Password";    
    public logInTitle: string = "ES Log In";
    public loginSSO: string = "ES Log In SSO";
	public loginFailed: string = "ES Login failed. Please make sure you have entered the correct credentials.";
	public loginSsoFailed: string = "ES Login using Single Sign-On failed.";
    public welcomeTo: string = "ES Welcome to";
    public weUseCookies: string = "ES We Use Cookies";
    public cookiesPolicyVersionUpdated: string = "ES We updated our cookie policy since the last time you accepted it. You need to review and accept it again.";
    public cookiesPolicyDescription: string = "ES BMC SIM uses cookies and marketing pixels (also from third parties) to collect information about how visitors use the website and personalize content and ads. These cookies help us to provide you with the best possible online experience, to constantly improve our website and to deliver offers that are tailored to your interests.";
    public cookiesPolicyButtonsDescription: string = "ES By clicking the \"{acceptAllCookiesButton}\" button, you agree to the use of cookies. For more information about the usage of cookies or to change your settings, please click on \"{editCookiesSettingsButton}\". To operate the site with only technically necessary, internal cookies, please click on \"{onlyNecessaryCookiesButton}\".";
    public acceptAllCookies: string = "ES Accept All Cookies";
    public editYourCookiesSettings: string = "ES You can edit your settings for cookies \"{linkRedirectHere}\"";
    public onlyNecessaryCookies: string = "ES Only Necessary Cookies";
    public linkRedirectHere: string = "ES here";
    public cookiePolicy: string = "ES Cookie Policy";
	public cookiePolicyCookieAcceptedCookiesPolicyDescription: string = "ES Proof that the user accepted the cookie policy";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1: string = "ES BMC SIM uses cookies in a variety of ways, including for the following purposes:";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet1: string = "ES To provide BMC SIM and other applications that you use.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet2: string = "ES To authenticate and identify you when you use our websites and applications so we can provide you with the requested services.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet3: string = "ES To save your settings or the point at which you stopped using BMC SIM or other applications.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet4: string = "ES To measure your use of BMC SIM and other applications so we can improve them and tailor our websites and online services to your likely interests.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet5: string = "ES To understand your likely interests so we can provide you with more relevant content on BMC SIM. ";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2: string = "ES BMC SIM uses cookies with the following functions:";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisDescription: string = "ES Cookies that help us improve our website provide aggregated statistics such as the total number of website visitors and which website areas are most frequently viewed by users. These types of cookies may be installed by external analysis service providers working on our behalf.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisTitle: string = "ES Cookies for website performance analysis";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesDescription: string = "ES There are two types of functional cookies:{lineBreak}- Cookies that guarantee basic website functionality.{lineBreak}- Cookies that improve the user experience.{lineBreak}The latter type of cookies enables the portal to \"remember\" any entries or settings (e.g., login, language, font size, and other display preferences) you have configured over a specific period of time. This way, you do not need to configure them again each time you visit and navigate the website.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesTitle: string = "ES Functional Cookies";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription3: string = "ES The functions and properties of individual cookies are described below:";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesTitle: string = "ES {span}4.{/span}{span}For which purposes does BMC SIM use Cookies?{/span}";
	public cookiePolicyHowCanYouControlCookiesDescription1: string = "ES If you wish, you can control and/or delete cookies. You can find out how to do this here: {link}.";
	public cookiePolicyHowCanYouControlCookiesDescription2: string = "ES You can delete cookies that have been saved to your computer, and most browsers can be configured to prevent cookies from being stored. However, you may then have to configure certain settings manually each time you visit a site and accept that some functions may be restricted. Disabling all cookies may mean that some of the basic features on our website can no longer be used.";
	public cookiePolicyHowCanYouControlCookiesDescription3: string = "ES You can decide which types of BMC SIM cookies you wish to permit by clicking on the selection fields in our cookie controller below:";
	public cookiePolicyHowCanYouControlCookiesTitle: string = "ES {span}5.{/span}{span}How can you control cookies?{/span}";
	public cookiePolicyWhatIsACookieDescription: string = "ES A cookie is a small data packet exchanged between computer programs and stored on your end device (such as your desktop computer, notebook, or smartphone) when you visit a website. Cookies contain a cookie ID, which acts as a unique identifier for each specific cookie. This ID is comprised of a sequence of characters, which can be used to link websites and servers to the specific web browser in which the cookie is stored. This enables the websites and servers visited to distinguish the user's browser from other browsers that contain different cookies. A specific web browser can be recognized and identified via the unique cookie ID.";
	public cookiePolicyWhatIsACookieTitle: string = "ES {span}1.{/span}{span}What is a Cookie?{/span}";
	public cookiePolicyWhatPurposesDoCookiesServeDescription1: string = "ES The uses for the information generated by cookies are listed below.";
	public cookiePolicyWhatPurposesDoCookiesServeDescription2: string = "ES In addition to statistical analyses, this data and information is evaluated to improve data protection and data security in our company, in order to ensure that the personal data we process is protected in the most effective way.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet1: string = "ES Correctly displaying the contents of websites.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet2: string = "ES Optimise the contents of these sites and advertising displayed on them.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet3: string = "ES Guarantee the long-term functionality of IT systems and website technology.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet4: string = "ES Provide the information required for prosecution to the relevant authorities in the event of a cyber attack.";
	public cookiePolicyWhatPurposesDoCookiesServeTitle: string = "ES {span}3.{/span}{span}What purposes do Cookies serve?{/span}";
	public cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesDescription: string = "ES Cookies installed on your device by the companies that operate the sites you visit.";
	public cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesTitle: string = "ES First-party Cookies";
	public cookiePolicyWhichTypesOfCookiesExistFlashCookiesDescription: string = "ES These cookies are installed by websites that feature multimedia content. The Adobe Flash software is required to display this content. This speeds up the rate at which you can download content and save information.";
	public cookiePolicyWhichTypesOfCookiesExistFlashCookiesTitle: string = "ES Flash Cookies";
	public cookiePolicyWhichTypesOfCookiesExistPersistentCookiesDescription: string = "ES These cookies remain on your device when you close your web browser. They are reactivated each time you visit the website that originally generated this cookie.";
	public cookiePolicyWhichTypesOfCookiesExistPersistentCookiesTitle: string = "ES Persistent Cookies";
	public cookiePolicyWhichTypesOfCookiesExistSessionCookiesDescription: string = "ES These cookies are not permanently stored on your device. They are used to enhance your browsing experience on a website, such as by enabling you to switch between websites without needing to log back in. Closing your web browser deletes all session cookies from your computer.";
	public cookiePolicyWhichTypesOfCookiesExistSessionCookiesTitle: string = "ES Session Cookies";
	public cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesDescription: string = "ES Cookies saved on your device via the sites you visit. Companies install these cookies for website analysis purposes, to provide the operator with information such as the number of visitors to a particular Internet page.";
	public cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesTitle: string = "ES Third-party Cookies";
	public cookiePolicyWhichTypesOfCookiesExistTitle: string = "ES {span}2.{/span}{span}Which types of Cookies exist?{/span}";
	public youMustAcceptTheCookiePolicyBeforeUsingTheCookieController: string = "ES You must accept the {link}cookies policy{/link} before using the cookie controller.";
	public category: string = "ES Category";
	public duration: string = "ES Duration";
	public validity: string = "ES Validity";
	public description: string = "ES Description";
	public essential: string = "ES Essential";
	public years: string = "ES years";
	public selection: string = "ES Selection";
	public functionalCookies: string = "ES Functional Cookies";
	public functionalCookiesDescription: string = "ES These cookies provide you a more personalized experience on BMC SIM and remember choices made on the portal. For example, functionality cookies can be used to remember language preferences.";
	public essentialCookies: string = "ES Essential Cookies";
	public essentialCookiesDescription: string = "ES Essential cookies for basic functions.{lineBreak}These cookies are used for enabling the basic functions of the BMC SIM website and the online shop. These cookies cannot be deselected.";
	public cookieName: string = "ES Name";
	public information: string = "ES Information";
	public show: string = "ES Show";
	public notRegistered: string = "ES Not registered ?";
	public termsOfUseUpdated: string = "ES We updated our terms of use since the last time you accepted it.";
	public termsAgreement: string = "ES Terms of Use Agreement";
	public youNeedToAcceptTerms: string = "ES You must accept our terms of use to access BMC SIM. Please review the terms by clicking on the following link: \"{linkTermsOfUse}\"";
	public onceYouHaveReviewedTheTerms: string = "ES Once you have reviewed and accepted the terms, you'll be able to continue using BMC SIM.";
	public byClickingOnAgree: string = "ES By clicking the \"I agree to the terms of use\" button, you agree to abide by the rules and guidelines outlined in our terms of use document.";
	public termsOfUse: string = "ES Terms of Use";
	public agreeToTerms: string = "ES I agree to the terms of use";
	public forgotPassword: string = "ES Forgot the password ?";
	public problemsLogIn: string = "ES Do you have problems logging into your account ?";
	public resetYourPassword: string = "ES Reset Your Password";
	public emailAdress: string = "ES Email Adress";
	public resetPassword: string = "ES Reset Password";
	public enterValidEmailAdress: string = "ES Please enter a valid email adress.";
	public contactFestoAdmin: string = "ES Please contact your Festo administrator to change your password.";
	public changePassword: string = "ES You can now change your password. We have sent you an email that allows you to reset your password.";
	public passwordResetFailed: string = "ES Failed to send email for password reset";
	public close: string = "ES Close";
	public checkEmail: string = "ES Please Check Your Email";
	public continue: string = "ES Continue";
	public youCannotAccessThis: string = "ES You cannot access the workbook without a license for BMC SIM.";
	public noBMCLicenseWasFound: string = "ES No license for BMC SIM was found";
	public yourOrganizationCurrently: string = "ES There is currently no BMC SIM license available in your Festo LX organization. You can access the overview of you organization's license by clicking \"Continue\". There you can add a BMC SIM license to your organization or check the current use of existing licenses.";
	public reducedFunctionality: string = "ES You can still use BMC SIM with reduced functionality by clicking \"Dismiss\".";
	public waitASecond: string = "ES Wait a second...";
	public dismiss: string = "ES Dismiss";
}