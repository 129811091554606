/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { imprintPdf, dataPrivacyPdf, setPreviousLocation, ResourceManager } from '../../index';

export default function Footer() {

    return (
        <>
            <header className="footerHeader flex centerAlign border-bottom">
                <b className="name">BMC SIM</b>
            </header>
            <div className="informations">
                <div className="generalLinks flex justify-content-center">
                    <a className="details" href={imprintPdf} target="_blank"
                        onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                        {ResourceManager.getValue("imprint")}
                    </a>
                    <a className="details" href={dataPrivacyPdf} target="_blank"
                        onMouseOver={e => e.currentTarget.style.color='#0091dc'} onMouseOut={e => e.currentTarget.style.color=''}>
                        {ResourceManager.getValue("dataPrivacy")}
                    </a>
                    <a className="details" href={"/" + ResourceManager.getLanguage() + "/cookies-policy"}
                        onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                        {ResourceManager.getValue("cookiePolicy")}
                    </a>
                    <Link className="details" to="/contact-us" onClick={() => setPreviousLocation(window.location.pathname)}
                        onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                        {ResourceManager.getValue("contactUs")}
                    </Link>
                    <a className="terms details" href={'https://www.festo.com/ca/en/e/legal/terms-and-conditions-of-sale-id_3747/'} target="_blank"
                        onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                        {ResourceManager.getValue("termsConditionSale")}
                    </a>
                </div>
                <div className="copyright flex justify-content-center">
                    &copy; {ResourceManager.getValue("copyright")}
                </div>
            </div>
        </>
    );
}