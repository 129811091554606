export class UrlUtils {
    static getQueryParams(url) {
        const urlParams = {};

        const indexStartQueryString = url.indexOf("?");
        if (indexStartQueryString < 0) {
            return urlParams;
        }

        const query = url.substring(indexStartQueryString).split("+").join(" ");
        const regExp = /[?&]?([^=]+)=([^&]*)/g;
        let tokens;

        tokens = regExp.exec(query);
        while (tokens) {
            urlParams[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);

            tokens = regExp.exec(query);
        }

        return urlParams;
    }

    static getQueryParam(url, param) {
        const urlParams = UrlUtils.getQueryParams(url);
        return urlParams[param] || "";
    }

    static queryParamExists(url, param) {
        const urlParams = UrlUtils.getQueryParams(url);
        return urlParams[param] !== undefined;
    }

    static getUrlWithoutQueryParams(url) {
        const queryStringIndex = url.indexOf('?');
        if (queryStringIndex !== -1) {
            return url.substring(0, queryStringIndex);
        }
        return url;
    }
}