import React from "react";
import "./Modal.css";
import { closeIcon } from "../../index";

const Modal = ({ isOpen, onClose, showCloseButton, children }) => {
    if (!isOpen) return null;

    return (
        <div className = "modal-background">
            <div className="modal-box">
                <div className="modal-content">
                    {children}
                </div>
                {showCloseButton && (
                    <button className="close-btn" onClick={onClose}>
                        <img className="close-icon" src={closeIcon} alt="Close" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Modal;