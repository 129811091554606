import React from 'react';
import './TermsOfUseModal.css';
import { ResourceManager, getTermsOfUseAgreementDate, setTermsOfUseAgreementDate } from '../../index';
import Modal from '../../components/modal/Modal';
import { getTermsOfUseUpdateDate } from './TermsOfUsePdf';

export function showTermsOfUseModal() {
	if (termsOfUseChanged()) {
		return true;
	} else {
		return !getTermsOfUseAgreementDate();
	}
}

function termsOfUseChanged() {
	let termsConsentDate = getTermsOfUseAgreementDate();
	let termsOfUseModified;
	if (termsConsentDate)
	{
		termsOfUseModified = getTermsOfUseUpdateDate() > termsConsentDate;
	}
	else
	{
		termsOfUseModified = false;
	}

	return termsOfUseModified;
}

export default function TermsOfUseConsentModal(termsOfUseModalOpen, handleTermsOfUseModalClose) {
	function onClickAgree() {
		setTermsOfUseAgreementDate();
		handleTermsOfUseModalClose()
	}

	function getTermsOfUseContent() {
		return (
			<div className="terms-content">
				<div className="welcome-text">
					{ResourceManager.getValue("welcomeTo")} BMC SIM
				</div>
				<div className="terms-agreement-text">
					{ResourceManager.getValue("termsAgreement")}
				</div>
				<div className="terms-description-section">
					{
						termsOfUseChanged() && <div className={`terms-modified-text`}>{ResourceManager.getValue("termsOfUseUpdated")}</div>
					}
					<div className="description-container">
						{ResourceManager.getValue("youNeedToAcceptTerms")
							.slice(0, ResourceManager.getValue("youNeedToAcceptTerms").indexOf("\"{linkTermsOfUse}\"", ""))}
						<a className="read-terms-link" href={"/" + ResourceManager.getLanguage() + "/terms-of-use"}>
							{ResourceManager.getValue("termsOfUse")}
						</a>
						{". "}
						{ResourceManager.getValue("onceYouHaveReviewedTheTerms")}
					</div>
					<div className="info-section">
						{ResourceManager.getValue("byClickingOnAgree") }
					</div>
				</div>
				<div className="center-button-container">
					<button
						className="agree-btn"
						onClick={() => onClickAgree()}
					>
						{ResourceManager.getValue("agreeToTerms")}
					</button>
				</div>
			</div>
		);
	}

	return (
		showTermsOfUseModal() ? (
			< Modal isOpen={termsOfUseModalOpen} onClose={handleTermsOfUseModalClose} showCloseButton={false} >
				{getTermsOfUseContent()}
			</Modal >
		) : (<></>)
	);
}