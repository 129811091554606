import React from 'react';
import './CookiesPolicyConsentModal.css';
import { ResourceManager, getAcceptedCookiesPolicy, cookiesPolicyBannerIcon, setAcceptedCookiesPolicy } from '../../index';
import Modal from '../../components/modal/Modal';
import { getCookiesPolicyUpdateDate } from '../../pages/cookiesPolicy/CookiesPolicy';

export function showCookiesPolicyModal() {
	if (cookiesPolicyChanged()) {
		return true;
	} else {
		return !getAcceptedCookiesPolicy();
	}
}

function cookiesPolicyChanged() {
	const cookiesPolicyConsent = getAcceptedCookiesPolicy();
	let cookiesPolicyConsentDate;

	if (cookiesPolicyConsent) {
		cookiesPolicyConsentDate = JSON.parse(cookiesPolicyConsent).date;
	}

	let cookiesPolicyModified;
	if (cookiesPolicyConsentDate)
	{
		cookiesPolicyModified = getCookiesPolicyUpdateDate() > cookiesPolicyConsentDate;
	}
	else
	{
		cookiesPolicyModified = false;
	}

	return cookiesPolicyModified;
}

export default function CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose) {

	function onClickAcceptAllCookiesAndContinue() {
		setAcceptedCookiesPolicy("all");
		handleCookiesPolicyModalClose()
	}

	function onClickAcceptOnlyNecessaryCookiesAndContinue() {
		setAcceptedCookiesPolicy("necessary");
		handleCookiesPolicyModalClose()
	}

	function getCookiesPolicyBanner() {
		return (
			<div class="cookies-policy-banner" onClick={() => handleCookiesPolicyModalOpen()}>
				<img src={cookiesPolicyBannerIcon} class="cookies-policy-banner-icon" alt="" />
				<span>{ResourceManager.getValue("cookiePolicy")}</span>
			</div>
		);
	}

	function getCookiesPolicyContent() {
		return (
			<div className="cookie-policy-content">
				<div className="welcome-text">
					{ResourceManager.getValue("welcomeTo")} BMC SIM
				</div>
				<div className="we-use-cookies-text">
					{ResourceManager.getValue("weUseCookies")}
				</div>
				<div className="cookies-description-section">
					{
						cookiesPolicyChanged() && <div className={`policy-modified-text`}>{ResourceManager.getValue("cookiesPolicyVersionUpdated")}</div>
					}
					<div className="description-container">
						{ResourceManager.getValue("cookiesPolicyDescription")}
					</div>
					<div className="buttons-container">
						{
							ResourceManager.getValue("cookiesPolicyButtonsDescription")
								.replace("\"{acceptAllCookiesButton}\"", "\"" + ResourceManager.getValue("acceptAllCookies") + "\"")
								.replace("\"{editCookiesSettingsButton}\"", "\"" + ResourceManager.getValue("editYourCookiesSettings"))
								.replace("\"{linkRedirectHere}\"", ResourceManager.getValue("linkRedirectHere") + "\"")
								.replace("\"{onlyNecessaryCookiesButton}\"", "\"" + ResourceManager.getValue("onlyNecessaryCookies") + "\"")
						}
					</div>
				</div>
				<div style={{ display: 'flex', margin: '-6px' }} >
					<button
						className="cookie-btn necessary-cookies-only-btn"
						onClick={() => onClickAcceptOnlyNecessaryCookiesAndContinue()}
					>
						{ResourceManager.getValue("onlyNecessaryCookies")}
					</button>
					<button
						className="cookie-btn"
						onClick={() => onClickAcceptAllCookiesAndContinue()}
					>
						{ResourceManager.getValue("acceptAllCookies")}
					</button>
				</div>
				<div className="edit-cookies-settings-section">
					{ResourceManager.getValue("editYourCookiesSettings")
						.slice(0, ResourceManager.getValue("editYourCookiesSettings").indexOf("\"{linkRedirectHere}\"", ""))}
					<a className="edit-cookies-link" href={"/" + ResourceManager.getLanguage() + "/cookies-policy"}>
						{ResourceManager.getValue("linkRedirectHere")}
					</a>
					.
				</div>
			</div>
		);
	}

	// Si la politique des cookies doit �tre accept�e : on affiche le modal ou, si le modal a �t� ferm�, on affiche la banni�re
	return (
		showCookiesPolicyModal() ? (
			cookiesPolicyModalOpen ? (
				< Modal isOpen={cookiesPolicyModalOpen} onClose={handleCookiesPolicyModalClose} showCloseButton={true} >
					{getCookiesPolicyContent()}
				</Modal >
			) : (
				getCookiesPolicyBanner()
			)
		) : (<></>)
	);
}