/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Header.css';
import { useState } from 'react';
import { festoLogo, ResourceManager } from '../../index';
import { Link } from 'react-router-dom';
import AccountButton from './AccountButton';

export default function Header() {
	const [appNameColor, setAppNameColor] = useState('');
	const [languageColor, setLanguageColor] = useState('');
	function ChangeLanguage(e, language) {		
		var iframe = document.getElementById('iframeBmc');		
			switch (language) {
				case 'FR':
					ResourceManager.changeLanguage('FR')
					if (iframe) {
						iframe.contentWindow.postMessage('LanguageFR', '*');
					}
					break;
				case 'ES':
					ResourceManager.changeLanguage('ES')
					if (iframe) {
						iframe.contentWindow.postMessage('LanguageES', '*');
					}
					break;
				case 'DE':
					ResourceManager.changeLanguage('DE');
					if (iframe) {
						iframe.contentWindow.postMessage('LanguageDE', '*');
					}
					break;
				default:
					ResourceManager.changeLanguage('EN')
					if (iframe) {
						iframe.contentWindow.postMessage('LanguageEN', '*');
					}
			}
	}

	return (
		<>
			<div className="bmcHeader centerAlign flex">
				<Link className="link" to={"/" + ResourceManager.getLanguage()} onMouseOver={() => setAppNameColor('var(--fwe-caerul)')}
					onMouseOut={() => setAppNameColor('')}>
					<div className="headerText" style={{ color: appNameColor }}>BMC SIM</div>
				</Link>
				<div className="buttonsHeader centerAlign flex">
					<AccountButton />
					<span style={{ display: 'inline-block', width: '25px' }} />
					<div className="dropdown">
						<button className="btn btn-white headerText centerAlign flex" data-bs-toggle="dropdown"
							aria-expanded="false" onMouseOver={() => setLanguageColor('var(--fwe-caerul)')}
							onMouseOut={() => setLanguageColor('')} >
							<div style={{ color: languageColor }}>{ResourceManager.getLanguage().toUpperCase()}</div>
						</button>
						<ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">
							<div className="arrow"></div>
							<li role="menuitem" title="English"><button className="btn btn-default dropdown-item" onClick={(e) => ChangeLanguage(e, 'EN')}>English</button></li>
							<li role="menuitem" title="French"><button className="btn btn-default dropdown-item" onClick={(e) => ChangeLanguage(e, 'FR')}>Fran&ccedil;ais</button></li>
							<li role="menuitem" title="Deutsch"><button className="btn btn-default dropdown-item" onClick={(e) => ChangeLanguage(e, 'DE')}>Deutsch</button></li>
							<li role="menuitem" title="Spanish"><button className="btn btn-default dropdown-item" onClick={(e) => ChangeLanguage(e, 'ES')}>Espa&ntilde;ol</button></li>
						</ul>
					</div>
					<span style={{ display: 'inline-block', width: '45px' }}/>
					<a className="centerAlign flex" href="https://www.festo.com/ca/en/" target="_blank">
						<img className="logoFesto" src={festoLogo} alt="FestoLogo"/>
					</a>
				</div>
			</div>
		</>
	)
}