/* eslint-disable react/jsx-no-target-blank */
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import CookiesPolicyConsentModal from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import { Link } from 'react-router-dom';
import { imprintPdf, dataPrivacyPdf, setPreviousLocation, ResourceManager } from '../../index';
import './Information.css';
import { useContext } from 'react';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';

export default function Information() {
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = useContext(CookiesPolicyConsentContext);

    function getInformationItem(itemTitle, linkElement) {
        return (
            <div className="information-item">
                <div className="item-title">{itemTitle}</div>
                {linkElement}
            </div>
        );
	}

    function getInformations() {
        return (
			<div className="information">
				<h1 className="information-headline">{ResourceManager.getValue("information")}</h1>
				{
				<div>
					{
                        getInformationItem(ResourceManager.getValue("imprint"),
                            <a className="show-details" href={imprintPdf} target="_blank"
                                onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                                {ResourceManager.getValue("show")}
                            </a>
						)
					}
                    {
                        getInformationItem(ResourceManager.getValue("dataPrivacy"),
                            <a className="show-details" href={dataPrivacyPdf} target="_blank"
                                onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                                {ResourceManager.getValue("show")}
                            </a>
                        )
                    }
                    {
                        getInformationItem(ResourceManager.getValue("cookiePolicy"),
                            <a className="show-details" href={"/" + ResourceManager.getLanguage() + "/cookies-policy"}
                                onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                                {ResourceManager.getValue("show")}
                            </a>
                        )
                    }
                    {
                        getInformationItem(ResourceManager.getValue("contactUs"),
                            <Link className="show-details" to="/contact-us" onClick={() => setPreviousLocation(window.location.pathname)}
                                onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                                {ResourceManager.getValue("show")}
                            </Link>
                        )
                    }
                    {
                        getInformationItem(ResourceManager.getValue("termsConditionSale"),
                            <a className="show-details" href={'https://www.festo.com/ca/en/e/legal/terms-and-conditions-of-sale-id_3747/'} target="_blank"
                                onMouseOver={e => e.currentTarget.style.color = '#0091dc'} onMouseOut={e => e.currentTarget.style.color = ''}>
                                {ResourceManager.getValue("show")}
                            </a>
                        )
                    }
				</div>
				}
			</div>
        );
    }

    return (
        <div className="BMC-Sim">
            <header className="BMC-Header">
                <Header />
            </header>
            {CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
            <div className="separator" />
            {getInformations()}
            <footer className="BMC-Footer">
                <Footer />
            </footer>
        </div>
    );
}