import axios from "axios";

export function setLxTokens(lxToken, refreshToken, cookiesPolicyToken) {

    localStorage.setItem("lxToken", lxToken);
    localStorage.setItem("lxRefreshToken", refreshToken);
    localStorage.setItem("lxCookiesPolicyToken", cookiesPolicyToken);
}

export function getLxTokens() {
    return {
        lxToken: localStorage.getItem("lxToken"),
        lxRefreshToken: localStorage.getItem("lxRefreshToken"),
        lxCookiesPolicyToken: localStorage.getItem("lxCookiesPolicyToken")
    };
}

export async function fetchBmcLicensesFromLx(profilesInfos) {
    const url = process.env.REACT_APP_API_URL + "updateLicenses";
    let lxToken = "";
    let lxRefreshToken = "";
    let lxCookiesPolicyToken = "";

    let updateLicenseInfo = {
        CurrentOrganizationId: profilesInfos.currentOrganizationId,
        OrganizationIds: profilesInfos.organizationIds,
        LxToken: localStorage.getItem("lxToken")
    }

    if (updateLicenseInfo.LxToken)
    {
        var response;
        try {
            response = await axios.post(url, updateLicenseInfo, {
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                }
            }, { withCredentials: true })
        }
        catch (error) {
            console.log(error);

            if (error.response.data) {
                lxToken = error.response.data["lxToken"] ?? "";
                lxRefreshToken = error.response.data["lxRefreshToken"] ?? "";
                lxCookiesPolicyToken = error.response.data["lxCookiesPolicyToken"] ?? "";
            }

            if (lxToken && lxRefreshToken && lxCookiesPolicyToken) {
                setLxTokens(lxToken, lxRefreshToken, lxCookiesPolicyToken);
            }
        }
        if (response) {
            lxToken = response.data["lxToken"] ?? "";
            lxRefreshToken = response.data["lxRefreshToken"] ?? "";
            lxCookiesPolicyToken = response.data["lxCookiesPolicyToken"];
            if (lxToken && lxRefreshToken && lxCookiesPolicyToken) {
                setLxTokens(lxToken, lxRefreshToken, lxCookiesPolicyToken);
            }
        }
    }

    return {
        lxToken,
        lxRefreshToken,
        lxCookiesPolicyToken
    };
}

export async function refreshTokens() {
    let lxTokens = getLxTokens();

    if (lxTokens.lxToken && lxTokens.lxRefreshToken && lxTokens.lxCookiesPolicyToken) {
        const url = process.env.REACT_APP_API_URL + "refreshLxTokens";

        var response;
        try {
            response = await axios.post(url, lxTokens, {
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                }
            }, { withCredentials: true })
        }
        catch (error) {
            console.log(error);

            if (error.response.data) {
                let lxToken = error.response.data.lxToken ?? "";
                let lxRefreshToken = error.response.data.lxRefreshToken ?? "";
                let lxCookiesPolicyToken = error.response.data.lxCookiesPolicyToken ?? "";
                setLxTokens(lxToken, lxRefreshToken, lxCookiesPolicyToken);
            }
        }
        if (response) {
            let lxToken = response.data["lxToken"] ?? "";
            let lxRefreshToken = response.data["lxRefreshToken"] ?? "";
            let lxCookiesPolicyToken = response.data["lxCookiesPolicyToken"];

            if (lxToken && lxRefreshToken && lxCookiesPolicyToken) {
                setLxTokens(lxToken, lxRefreshToken, lxCookiesPolicyToken);
            }
        }
    }
}

export async function SetLxCookies(lxToken, refreshToken, cookiesPolicyToken) {
    const url = process.env.REACT_APP_API_URL + "setLxCookies";
    let lxTokens = getLxTokens();
    await axios.post(url, lxTokens, {
        headers: {
            "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
        }
    }, { withCredentials: true }
    ).then(response => {
        if (response.status === 200) {
        }
    }).catch(error => {
        if (error.response.status === 401) {
        }
    });
}