import React from 'react';
import './ContactUs.css';
import { useNavigate } from 'react-router-dom';
import { ResourceManager, getProfileInfos, isLoggedIn } from "../../index";
import CountrySelector from './CountrySelector'
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import config from '../../appsettings.json';
import axios from "axios";

export default function ContactUs() {
    const navigate = useNavigate();

    async function submitForm() {
        const formElement = document.getElementById("contactUsForm");
        const formData = new FormData(formElement);

        let contactUsInfo = {
            RequesterName: formData.get("RequesterName"),
            RequesterEmail: formData.get("RequesterEmail"),
            Country: formData.get("country"),
            Body: formData.get("Body")
        }

        const url = process.env.REACT_APP_API_URL + "contactus";
        await axios.post(url, contactUsInfo, {
            headers: {
                "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
            }
        }, { withCredentials: true }
        ).then(response => {
            if (response.status === 200) {
                navigate("email-sent-successfully")
            }
        }).catch(error => {
            if (error.response.status === 401) {
            }
        });
    }

    return (
        <>
            <header className="BMC-Header">
                <Header />
            </header>
            <div className="separator" />
            <div className="contactUs-Main">
                <header className="contactUs-Header">
                    <b>{ResourceManager.getValue('contactUsTitle')}</b>
                </header>
                <div className="question">
                    {ResourceManager.getValue('howCanHelp')}
                </div>
                <form id="contactUsForm" className="border-bottom" >
                    <label className="labels" for="name">{ResourceManager.getValue('name')}</label>
                    <br/>
                    <input style={{ width: "100%" }} type="text" id="RequesterName" name="RequesterName" defaultValue={isLoggedIn() ? getProfileInfos().firstName + " " + getProfileInfos().lastName : ""} required="true" />
                    <br/>
                    <br />
                    <label className="labels" for="email">{ResourceManager.getValue('email')} </label>
                    <br />
                    <input style={{ width: "100%" }} type="text" id="RequesterEmail" name="RequesterEmail" defaultValue={isLoggedIn() ? getProfileInfos().email : ""} required="true" />
                    <br />
                    <br />
                    <label className="labels" for="country">{ResourceManager.getValue('country')}</label>
                    <br />
                    <CountrySelector style={{ width: "100%" }} />
                    <br />
                    <br />
                    <label className="labels" for="message">{ResourceManager.getValue('message')}</label>
                    <br />
                    <textarea style={{ width: "100%" }} rows="7" id="Body" name="Body" required="true" />
                    <br />
                    <br />
                    <div className="flex rightAlign">
                        <button className="submitButton rightAlign" type="button" onClick={submitForm}>
                            {ResourceManager.getValue('submit')}
                        </button>
                    </div>
                    <br />
                </form>
                <div className="mandatoryInfos flex centerAlign">
                    {ResourceManager.getValue('mandatoryInfos')}
                </div>
            </div>
            <footer className="BMC-Footer">
                <Footer />
            </footer>
        </>
    );
}