// File name should follow this format : YYMMDD-terms-of-use-bmc.pdf
// Where YYMMDD is the update date of the terms of use
const termsOfUsePdfs = require.context('../../files/termsOfUse', true);
const termsOfUsePdfsList = termsOfUsePdfs.keys().map(termsOfUsePdf => termsOfUsePdfs(termsOfUsePdf));
let latestTermsOfUse = "";
let termsUpdateDate = 0;

function findLatestTermsOfUse() {
    if (latestTermsOfUse) {
        return latestTermsOfUse;
    }

    const regex = /\/(\d{6})-terms-of-use-bmc/;

    for (let termsOfUsePdf in termsOfUsePdfsList) {
        const match = regex.exec(termsOfUsePdfsList[termsOfUsePdf]);

        if (match && match[1] && match[1] > termsUpdateDate) {
            termsUpdateDate = match[1];
            latestTermsOfUse = termsOfUsePdfsList[termsOfUsePdf];
        }
    }
}

export function getTermsOfUsePdf() {
    if (latestTermsOfUse) {
        return latestTermsOfUse;
    }

    findLatestTermsOfUse();
    return latestTermsOfUse;
}

export const getTermsOfUseUpdateDate = () => {
    if (termsUpdateDate !== 0) {
        return termsUpdateDate;
    }

    findLatestTermsOfUse();
    return termsUpdateDate;
}