import React from 'react';
import './LandingPage.css';
import { bmcPicture1, bmcPlaceholder1, bmcPicture2, bmcPlaceholder2, bmcPicture3, bmcPlaceholder3 } from '../../index';
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import CookiesPolicyConsentModal from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import ProgressiveImg from '../../components/progressiveImage/ProgressiveImage';
import { useContext } from 'react';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';

export default function LandingPage() {
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = useContext(CookiesPolicyConsentContext);

    return (
        <div className="BMC-Sim">
            <header className="BMC-Header">
                <Header/>
            </header>
            {CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
            <div className="separator" />
            <div className="BMC-MainContent">
                <header className="appName">
                    <b>BMC SIM</b>
                    <span style={{ display: 'inline-block', width: '45px' }} />
                    Basic Motor Control System Simulator
                </header>
                <div className="bmcPictures">
                    <div className="bmc-picture">
                        <ProgressiveImg
                            src={bmcPicture1}
                            placeholderSrc={bmcPlaceholder1}
                            alt="BmcPictures"
                            height="100%"
                            width="100%"
                        />
                    </div>
                    <div className="imageSeparator" />
                    <div className="bmc-picture">
                        <ProgressiveImg
                            src={bmcPicture2}
                            placeholderSrc={bmcPlaceholder2}
                            alt="BmcPictures"
                            height="100%"
                            width="100%"
                        />
                    </div>
                    <div className="imageSeparator" />
                    <div className="bmc-picture">
                        <ProgressiveImg
                            src={bmcPicture3}
                            placeholderSrc={bmcPlaceholder3}
                            alt="BmcPictures"
                            height="100%"
                            width="100%"
                        />
                    </div>
                </div>
            </div>
            <footer className="BMC-Footer">
                <Footer />
            </footer>
        </div>
    );
}