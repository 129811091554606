import React, { useState, useEffect } from 'react';
import './AccountButton.css';
import { getLoginNameString, getProfileInfos, ResourceManager, accountIconG, accountIconB, logoutIconG, logoutIconW } from '../../index';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../provider/authProvider";
import axios from "axios";

export default function AccountButton() {
	const { setToken } = useAuth();
	const navigate = useNavigate();
	const [accountIcon, setAccountIcon] = useState(accountIconG);
	const [accountText, setAccountText] = useState("inherit");
	const [logoutIcon, setLogoutIcon] = useState(logoutIconG);
	const [logoutText, setLogoutText] = useState("var(--fwe-black)");
	// Utilis� pour preload les icones en cache
	const iconMap = new Map();

	function cacheIcon(iconName, iconData) {
		iconMap.set(iconName, iconData);
	}

	// Utilisation de useEffect pour preload les icones en cache
	useEffect(() => {
		cacheIcon("logoutIconW", logoutIconW);
		cacheIcon("accountIconB", accountIconB);
	});

	function GetUserFullName() {
		return GetUserFirstName() + " " + GetUserLastName();
	}

	function GetUserFirstName() {
		var profileInfos = getProfileInfos();
		return profileInfos.firstName ? profileInfos.firstName : "";
	}

	function GetUserLastName() {
		var profileInfos = getProfileInfos();
		return profileInfos.lastName ? profileInfos.lastName : "";
	}

	function GetUserEmail() {
		var profileInfos = getProfileInfos();
		return profileInfos.email ? profileInfos.email : "";
	}

	function GetProfilePictureThumbnail() {
		var profileInfos = getProfileInfos();
		return profileInfos.profilePictureThumbnailJpegBase64 ? profileInfos.profilePictureThumbnailJpegBase64 : "";
	}

	function onMouseOverAccountButton() {
		setAccountIcon(iconMap.get("accountIconB"));
		setAccountText("var(--fwe-caerul)");
	}

	function onMouseOutAccountButton() {
		setAccountIcon(accountIconG);
		setAccountText("inherit");
	}

	function onMouseOverLogoutButton() {
		setLogoutIcon(iconMap.get("logoutIconW"));
		setLogoutText("var(--fwe-white)");
	}

	function onMouseOutLogoutButton() {
		setLogoutIcon(logoutIconG);
		setLogoutText("var(--fwe-black)");
	}

	async function Logout() {
		var profileInfos = getProfileInfos();
		let userInfo = {
			userId: profileInfos.id,
			organizationId: profileInfos.organizationId,
			lastConnection: new Date()
		}
		const url = process.env.REACT_APP_API_URL + "Logout";
		await axios.post(url, userInfo, {
			headers: {
				"Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
			}
		}, { withCredentials: true })
			.then(response => {
			})
			.catch(error => {
				console.log(error);
			})

		localStorage.removeItem('profileInfos');
		localStorage.removeItem('lxToken');
		localStorage.removeItem('lxRefreshToken');
		setToken();
		navigate('/');
	}

	return (
		<>
			{getProfileInfos() ? (
				<div className="dropdown account-menu">
					<button
						className="btn btn-white headerText account-btn"
						data-bs-toggle="dropdown"
						title="Account"
						aria-expanded="false"
						onMouseOver={() => onMouseOverAccountButton()}
						onMouseOut={() => onMouseOutAccountButton()}
					>
						<img className="account-icon" src={accountIcon} alt="LoginImage" />
						<div style={{ color: accountText }}>{getLoginNameString()}</div>
					</button>
					<div className="dropdown-menu" aria-labelledby="profile-dropdown">
						<div className="arrow"></div>
						<div className="account-info">
							<div className="flex-grow-0 profile-popup-img">
								{GetProfilePictureThumbnail() ? (
									<img className="avatar" src={`data:image/jpeg;base64, ${GetProfilePictureThumbnail()}`} alt="" />
								) : (
									<div className="avatar avatar-placeholder">
										<div className="d-flex align-items-center justify-content-center avatar-letter-container">
											<span className="avatar-letter">{GetUserFirstName().length > 0 ? GetUserFirstName()[0] : ""}</span>
										</div>
									</div>
								)}
							</div>
							<div className="flex-grow-1">
								<div className="flex-grow-1 profile-dropdown-name">{GetUserFullName()}</div>
								<div className="flex-grow-1 profile-dropdown-email">{GetUserEmail()}</div>
							</div>
						</div>
						<button
							className="logoutBtn"
							onClick={() => Logout()}
							onMouseOver={() => onMouseOverLogoutButton()}
							onMouseOut={() => onMouseOutLogoutButton()}
						>
							<img src={logoutIcon} className="logoutIcon" alt="" />
							<span style={{ color: logoutText }}>{ResourceManager.getValue("logout")}</span>
						</button>
					</div>
				</div>
			) : (
				<button
					className="btn btn-white headerText account-btn"
					onClick={() => navigate('/login')}
					title="Login"
					onMouseOver={() => onMouseOverAccountButton()}
					onMouseOut={() => onMouseOutAccountButton()}
				>
					<img className="account-icon" src={accountIcon} alt="LoginImage" />
					<div style={{ color: accountText }}> {getLoginNameString()}</div>
				</button>
			)}
		</>
	);
}