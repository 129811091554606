import React from 'react';
import { useState, useEffect } from 'react';
import './Workbook.css';
import Header from '../../content/header/Header';
import axios from "axios";
import { LoadingIndicator } from '@festo-ui/react'
import NoBmcLicenseModal from '../../content/noBmcLicense/NoBmcLicenseModal';
import { ResourceManager } from '../../index';

export default function Workbook() {
    const [workbook, setWorkbook] = useState(null);
    const [userHasNoLicense, setUserHasNoLicense] = useState(false);

    useEffect(() => {
        if (!workbook) {
            const urllogin = process.env.REACT_APP_API_URL + "workbook";

            axios.get(urllogin, {
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }).then(response => {
                if (response.status === 200) {
                    setUserHasNoLicense(false);
                    setWorkbook(
                        URL.createObjectURL(
                            new Blob(
                                [response.data],
                                { type: 'application/pdf' }
                            )
                        )
                    );
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    setUserHasNoLicense(true);
                }
            });
        }
    }, [workbook]);

    return (
        <div className="workbook-page">
            <header className="BMC-Header">
                <Header />
            </header>
            <div className="separator" />
            {workbook &&
                <iframe
                    title="Basic Motor Control Technology Workbook"
                    src={workbook + "#view=FitH&toolbar=0"}
                    className="workbook-iframe"
                />}
            {!userHasNoLicense && !workbook &&
                <LoadingIndicator />}
            {userHasNoLicense && !workbook &&
                <NoBmcLicenseModal
                    showDismiss={false}
                    infoText={ResourceManager.getValue("youCannotAccessThis")}
                />
                }
        </div>
    );
}