import { i18n } from '../index'

//@ts-ignore
import resourcesEn from "./resources.en.ts";
//@ts-ignore
import resourcesFr from "./resources.fr.ts";
//@ts-ignore
import resourcesDe from "./resources.de.ts";
//@ts-ignore
import resourcesEs from "./resources.es.ts";

//@ts-ignore
import BaseResource from './base-resource.ts';

//Cette classe est utilis�e pour g�rer les ressource dans les diff�rentes langues et g�rer ave i18n le language du site
export default class ResourceManager {
    private static resourceEn = new resourcesEn();
    private static resourceFr = new resourcesFr();
    private static resourceDe = new resourcesDe();
    private static resourceEs = new resourcesEs();

    private static resource: BaseResource = ResourceManager.getResource();

    public static getValue(key: string): string {
        return ResourceManager.getResource().getValue(key);
    }

    public static changeLanguage(lang: string) {
        i18n.changeLanguage(lang.toLowerCase());        
        var iframe = document.getElementById('iframeBmc');
        if (iframe) {
            (iframe as HTMLIFrameElement).contentWindow.postMessage(                
                   'Language' + lang.toLowerCase(), '*');
        }        
    }

    public static getLanguage(): string {
        //V�rifie que le language dans l'URL est valide et sinon redirige vers un URL avec le bon language
        var path = "/" + i18n.language;

        switch (window.location.pathname.toString().substring(1, 3)) {
            case "lo":
            case "co":
            case "ho":
                window.location.pathname = path + window.location.pathname;
                break;
            case i18n.language:
                break;
            default:
                window.location.pathname = path + window.location.pathname.substring(3)
        }

        return i18n.language;
    }

    private static getResource(): BaseResource {
        switch (i18n.language) {
            case "en":
                return ResourceManager.resourceEn;
            case "fr":
                return ResourceManager.resourceFr;
            case "de":
                return ResourceManager.resourceDe;
            case "es":
                return ResourceManager.resourceEs;
            default:
                return ResourceManager.resourceEn;
        }
    }
}