import React, { useState } from 'react';
import { ResourceManager } from '../../index';
import { AlertModal } from '@festo-ui/react'
import './NoBmcLicenseModal.css';

const NoBmcLicenseModal = ({ showDismiss, infoText }) => {
	const [bmcLicenseModalOpen, setBmcLicenseModalOpen] = useState(true);

	function handleContinue() {
		window.open("https://lx.festo.com", "_blank");
	}

	function handleBmcLicenseModalClose() {
		setBmcLicenseModalOpen(false);
	}

	if (showDismiss) {
		return (
			< AlertModal
				isOpen={bmcLicenseModalOpen}
				onClose={handleBmcLicenseModalClose}
				onOk={handleContinue}
				onCancel={handleBmcLicenseModalClose}
				strong={true}
				subtitle={ResourceManager.getValue("waitASecond")}
				alertType="warning"
				title={ResourceManager.getValue("noBMCLicenseWasFound")}
				cancel={ResourceManager.getValue("dismiss")}
				ok={ResourceManager.getValue("continue")}
				body={ResourceManager.getValue("yourOrganizationCurrently") + " " + infoText}
			/>
		);
	}
	else
	{
		return (
			< AlertModal
				isOpen={bmcLicenseModalOpen}
				onClose={handleBmcLicenseModalClose}
				onOk={handleContinue}
				strong={true}
				subtitle={ResourceManager.getValue("waitASecond")}
				alertType="warning"
				title={ResourceManager.getValue("noBMCLicenseWasFound")}
				ok={ResourceManager.getValue("continue")}
				body={ResourceManager.getValue("yourOrganizationCurrently") + " " + infoText}
			/>
		);
	}
}

export default NoBmcLicenseModal;