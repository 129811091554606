import React from 'react';
import './CookiesPolicy.css';
import { ResourceManager, getAcceptedCookiesPolicy, setAcceptedCookiesPolicy } from '../../index';
import InputRadioGroup from '../../components/inputRadio/InputRadioGroup';
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import CookiesPolicyConsentModal, { showCookiesPolicyModal } from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';
import config from '../../appsettings.json';


// Svp mettre � jour la date de r�vision de la politique de cookies lorsque vous mettez � jour la politique de cookies.
// Ce param�tre est utilis� pour d�terminer si l'utilisateur a accept� la derni�re version de la politique de cookies.
// Format : 'YYMMDD'
export function getCookiesPolicyUpdateDate() {
    return '240606';
}

export default function CookiePolicy() {

    const currentAcceptedCookiesLevel = getAcceptedCookiesPolicy() ? JSON.parse(getAcceptedCookiesPolicy()).cookiesConsentLevel : undefined;
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = React.useContext(CookiesPolicyConsentContext);
    const [selectedCookiesLevelValue, setSelectedCookiesLevelValue] = React.useState(currentAcceptedCookiesLevel);

    function handleCookiesLevelChange(value) {
        setSelectedCookiesLevelValue(value);
        setAcceptedCookiesPolicy(value)
    }

    function getDescriptionElement(description) {
        return <p className="imprint-and-data-privacy-description">{description}</p>
    }

    function getCookieType(title, description) {
        return (
            <li className="cookie-type">
                <div className="title">{title}</div>
                <div>{description}</div>
            </li>
        );
    }

	function getCookieTypeRow(name, category, duration, description) {
        return (
            <tr>
                <td className="title">{name}</td>
                <td>{category}</td>
                <td>{duration}</td>
                <td>{config.bmcSimProdUrl}</td>
                <td>{description}</td>
            </tr>
        );
    }

    function getCookiePurposeList(key, items) {
        return <ul>
            {items.map((item, itemIndex) => <li key={`${key}-${itemIndex}`}>{item}</li>)}
        </ul>;
    }

    function getInputRadioLabel(title, description) {
        return <>
            <div className="title">{title}</div>
            <div>{description}</div>
        </>;
    }

    function replaceSpanTag(text) {
        return text
            .split("{span}")
            .join("{/span}")
            .split("{/span}")
            .map((part, index) => index % 2 === 0 ? part : <span className="replaced-span">{part}</span>);
    }

    function replaceLineBreakTag(text) {
        return text.split("{lineBreak}").map((part, index, array) => {
            return index < array.length - 1 ? <>{part}<br /></> : part;
        });
    }

    function replaceLinkTagWithLink(text, link) {
        return text.split("{link}").map((part, index, array) => {
            return index < array.length - 1 ?
                <>{part}<a className="about-cookies-link" href={link} target="_blank" rel="noopener noreferrer">{link}</a></> : part;
        });
    }

    function replaceLinkTagWithLinkElement(text, linkElement) {
        return text
            .split("{link}")
            .join("{/link}")
            .split("{/link}")
            .map((part, index) => index % 2 === 0 ? part : linkElement);
    }

    function getCookiesPolicy() {
        return (
            <div className="cookie-policy">
                <h1 className="cookie-policy-headline">{ResourceManager.getValue("cookiePolicy")}</h1>
                <div className="numbered-subtitle">
                    <span>{replaceSpanTag(ResourceManager.getValue("cookiePolicyWhatIsACookieTitle"))}</span>
                </div>

                {getDescriptionElement(ResourceManager.getValue("cookiePolicyWhatIsACookieDescription"))}
                <div className="numbered-subtitle">
                    <span>{replaceSpanTag(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistTitle"))}</span>
                </div>
                <ul className="point-list">
                    {getCookieType(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesTitle"), ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesDescription"))}
                    {getCookieType(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesTitle"), ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesDescription"))}
                    {getCookieType(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistPersistentCookiesTitle"), ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistPersistentCookiesDescription"))}
                    {getCookieType(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistSessionCookiesTitle"), ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistSessionCookiesDescription"))}
                    {getCookieType(ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistFlashCookiesTitle"), ResourceManager.getValue("cookiePolicyWhichTypesOfCookiesExistFlashCookiesDescription"))}
                </ul>

                <div className="numbered-subtitle">
                    <span>{replaceSpanTag(ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeTitle"))}</span>
                </div>
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescription1"))}
                <div className="point-list">
                {getCookiePurposeList("cookie-purpose", [ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet1"), ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet2"), ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet3"), ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet4")])}
                </div>
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyWhatPurposesDoCookiesServeDescription2"))}
                <div className="numbered-subtitle">
                    <span>{replaceSpanTag(ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesTitle"))}</span>
                </div>
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1"))}
                <div className="point-list">
                {getCookiePurposeList("festo-cookie-purpose", [ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet1"), ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet2"), ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet3"), ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet4"), ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet5")])}
                </div>
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2"))}

                <ul className="cookie-functions">
                    <li>
                        <div className="title">{ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesTitle")}</div>
                        <div>{replaceLineBreakTag(ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesDescription"))}</div>
                    </li>
                    <li>
                        <div className="title">{ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisTitle")}</div>
                        <div>{ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisDescription")}</div>
                    </li>
                </ul>

                {getDescriptionElement(ResourceManager.getValue("cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription3"))}

                <table className="cookies-table">
                    <thead>
                        <tr>
                            <th>{ResourceManager.getValue("cookieName")}</th>
                            <th>{ResourceManager.getValue("category")}</th>
                            <th>{ResourceManager.getValue("duration")}</th>
                            <th>{ResourceManager.getValue("validity")}</th>
                            <th>{ResourceManager.getValue("description")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCookieTypeRow("Accepted-Cookies-Policy", ResourceManager.getValue("essential"), "2 " + ResourceManager.getValue("years"), ResourceManager.getValue("cookiePolicyCookieAcceptedCookiesPolicyDescription"))}
                    </tbody>
                </table>

                <div className="numbered-subtitle">
                    <span>{replaceSpanTag(ResourceManager.getValue("cookiePolicyHowCanYouControlCookiesTitle"))}</span>
                </div>
                {getDescriptionElement(replaceLinkTagWithLink(ResourceManager.getValue("cookiePolicyHowCanYouControlCookiesDescription1"), "http://www.aboutcookies.org/"))}
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyHowCanYouControlCookiesDescription2"))}
                {getDescriptionElement(ResourceManager.getValue("cookiePolicyHowCanYouControlCookiesDescription3"))}
                {
                    showCookiesPolicyModal() && <div className="alert-warning">
                        {
                            replaceLinkTagWithLinkElement(
                                ResourceManager.getValue("youMustAcceptTheCookiePolicyBeforeUsingTheCookieController"),
                                <span className={`clickable-text`} onClick={() => handleCookiesPolicyModalOpen()}><u>{ResourceManager.getValue("cookiePolicy")}</u></span>
                            )
                        }
                    </div>
                }

                <div className="imprint-and-data-privacy-description">
                    <div className="title">{ResourceManager.getValue("selection") + " " + ResourceManager.getValue("description")}</div>
                    <InputRadioGroup
                        id="cookieLevel"
                        label={ResourceManager.getValue("cookiePolicyWhatAreYourOptionsTitle")}
                        description={ResourceManager.getValue("cookiePolicyWhatAreYourOptionsDescription")}
                        value={selectedCookiesLevelValue}
                        onChange={handleCookiesLevelChange}
                        disabled={currentAcceptedCookiesLevel === undefined}
                        values={[
                            {
                                id: "radio-functional-cookies",
                                label: getInputRadioLabel(ResourceManager.getValue("functionalCookies"), ResourceManager.getValue("functionalCookiesDescription")),
                                value: "all"
                            },
                            {
                                id: "radio-essential-cookies",
                                label: getInputRadioLabel(ResourceManager.getValue("essentialCookies"), replaceLineBreakTag(ResourceManager.getValue("essentialCookiesDescription"))),
                                value: "necessary"
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="BMC-Sim">
            <header className="BMC-Header">
                <Header />
            </header>
            {CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
            <div className="separator" />
            {getCookiesPolicy()}
            <footer className="BMC-Footer">
                <Footer />
            </footer>
        </div>
    );
}