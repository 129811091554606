import 'bootstrap';
import '@festo-ui/web-essentials/dist/css/festo-web-essentials.min.css';
import '@festo-ui/react/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'jquery/dist/jquery.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import "../node_modules/bootstrap/js/dist/dropdown.js";
import App from './App';
import { ResourceManager } from './index';
import './index.css';
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { useAuth } from "./provider/authProvider";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import Login from "./pages/login/LoginPage";
import Home from "./pages/home/Home";
import OrganizationPage from "./pages/organizationPage/OrganizationPage";
import ContactUs from "./pages/contactUs/ContactUs"
import EmailSent from "./pages/contactUs/EmailSent"
import LandingPage from "./pages/landingPage/LandingPage"
import CookiesPolicy from "./pages/cookiesPolicy/CookiesPolicy"
import Information from "./pages/information/Information"
import Workbook from "./pages/workbook/Workbook"
import TermsOfUse from './pages/termsOfUse/TermsOfUse';
import axios from "axios";
import moment from "moment";

//Cr�e les param�tres pour le plugin de language (i18n)
await i18n.use(LanguageDetector)
    .init(
        {
            fallbackLng: ["en"],
            detection: {
                order: ["path", "localStorage", "htmlTag", "cookie"],
                       },
            interpolation: { escapeValue: false },
            supportedLngs: ["en", "es", "fr", "de"]
        }
    );

//D�cide s'il faut retourner la string login ou le pr�nom de l'utilisateur au header
function getLoginNameString() {
    var profileInfos = getProfileInfos();    
    return profileInfos ? profileInfos.firstName : ResourceManager.getValue("login");                   
}

function getAcceptedCookiesPolicy() {
    return localStorage.getItem('Accepted-Cookies-Policy');
}

function setAcceptedCookiesPolicy(level) {
    let cookiesPolicyConsent = {
        cookiesConsentLevel: level,
        date: moment().format("YYMMDD")
    };
    localStorage.setItem('Accepted-Cookies-Policy', JSON.stringify(cookiesPolicyConsent));
}

function getTermsOfUseAgreementDate() {
    return localStorage.getItem('Terms-Of-Use-Agreement-Date');
}

function setTermsOfUseAgreementDate(level) {
    localStorage.setItem('Terms-Of-Use-Agreement-Date', moment().format("YYMMDD"));
}

async function isLoggedIn() {
    var profileInfos = getProfileInfos();
    if (profileInfos) {
        const url = process.env.REACT_APP_API_URL + "IsLoggedInLx";

        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            })
            if (response.status === 200) {
                let infos = response.data;

                infos["currentOrganizationId"] = profileInfos.currentOrganizationId;

                if (profileInfos.profilePictureThumbnailJpegBase64) {
                    infos["profilePictureThumbnailJpegBase64"] = profileInfos.profilePictureThumbnailJpegBase64;
                } else {
                    infos["profilePictureThumbnailJpegBase64"] = '';
                }

                setProfileInfos(infos);
                //let bmcToken = infos["bmcToken"];
                //setToken(bmcToken);                
                return true;
            }
            else {
                alert('Une erreur est survenue lors de la requ�te.');
            }
        }
        catch (error) {
            console.log(error);
            return false;
        }
    } else {
        return false;
    }            
}

async function setProfileInfos(infos) {    
    localStorage.setItem('profileInfos', JSON.stringify(infos));
}

function getProfileInfos() {
    var infos;
    infos = localStorage.getItem('profileInfos');
    return JSON.parse(infos);
}

function getPathWithLanguage(path) {
    return "/" + ResourceManager.getLanguage() + path;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </React.StrictMode>
);

const Routes = () => {

    const { token } = useAuth();

    const routesForPublic = [
        {
            path: "/contact-us",
            element: <Navigate to={getPathWithLanguage("/contact-us")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/contact-us",
            element: <ContactUs />,
        },
        {
            path: "/contact-us/email-sent-successfully",
            element: <Navigate to={getPathWithLanguage("/contact-us/email-sent-successfully")} replace />
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/contact-us/email-sent-successfully",
            element: <EmailSent />,
        },
        {
            path: "/cookies-policy",
            element: <Navigate to={getPathWithLanguage("/cookies-policy")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/cookies-policy",
            element: <CookiesPolicy />,
        },
        {
            path: "/information",
            element: <Navigate to={getPathWithLanguage("/information")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/information",
            element: <Information />,
        },
        {
            path: "/terms-of-use",
            element: <Navigate to={getPathWithLanguage("/terms-of-use")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/terms-of-use",
            element: <TermsOfUse />,
        },
    ];


    const routesForNotAuthenticatedOnly = [        
        {
            path: "/",
            element: <Navigate to={getPathWithLanguage("")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage(),
            element: <LandingPage />,
        },
        {
            path: "/login",
            element: <Navigate to={getPathWithLanguage("/login")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/login",
            element: <Login />,
        },
    ];

    const routesForAuthenticatedOnly = [
        {
            path: "/home",
            element: <Navigate to={getPathWithLanguage("/home")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/home",
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/" + ResourceManager.getLanguage() + "/home",
                    element: <Home />,
                },
            ],
        },
        {
            path: "/" + ResourceManager.getLanguage(),
            element: <Navigate to={getPathWithLanguage("/home")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/login",
            element: <Navigate to={getPathWithLanguage("/home")} replace />,
        },        
        {
            path: "/",
            element: <Navigate to={getPathWithLanguage("/home")} replace />,
        },
        {
            path: "/login",
            element: <Navigate to={getPathWithLanguage("/home")} replace />,
        },
        {
            path: "/organizationPage",
            element: <Navigate to={getPathWithLanguage("/organizationPage")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/organizationPage",
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/" + ResourceManager.getLanguage() + "/organizationPage",
                    element: <OrganizationPage />,
                },
            ],
        },
        {
            path: "/workbook",
            element: <Navigate to={getPathWithLanguage("/workbook")} replace />,
        },
        {
            path: "/" + ResourceManager.getLanguage() + "/workbook",
            element: <Workbook />,
        },   

    ];

    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    return <RouterProvider router={router} />;
};

export default Routes;
export { getLoginNameString, getProfileInfos, setProfileInfos, isLoggedIn, i18n, getAcceptedCookiesPolicy, setAcceptedCookiesPolicy, getTermsOfUseAgreementDate, setTermsOfUseAgreementDate };

export { default as ContactUs } from './pages/contactUs/ContactUs';
export { default as EmailSent, setPreviousLocation } from './pages/contactUs/EmailSent';
export { default as Home } from './pages/home/Home';
export { default as OrganizationPage} from './pages/organizationPage/OrganizationPage';
export { default as LandingPage } from './pages/landingPage/LandingPage';
export { default as Login } from './pages/login/LoginPage';

export { default as ResourceManager } from './resources/resource-manager.ts';

export { default as homeIconB } from './assets/icons/home/home_16-b.svg';
export { default as homeIconG } from './assets/icons/home/home_16-g.svg';
export { default as orgIconB } from './assets/icons/organization/organization_16-b.svg';
export { default as orgIconG } from './assets/icons/organization/organization_16-g.svg';
export { default as infoIconB } from './assets/icons/info/info_16-b.svg';
export { default as infoIconG } from './assets/icons/info/info_16-g.svg';
export { default as docIconB } from './assets/icons/documentation/documentation_16-b.svg';
export { default as docIconG } from './assets/icons/documentation/documentation_16-g.svg';
export { default as accountIconB } from './assets/icons/user/user_16-b.svg';
export { default as accountIconG } from './assets/icons/user/user_16-g.svg';
export { default as cookiesPolicyBannerIcon } from './assets/icons/cookiesPolicyBanner/cookiesPolicyBanner_16.svg';
export { default as logoutIconW } from './assets/icons/logout/logout_32-w.svg';
export { default as logoutIconG } from './assets/icons/logout/logout_32-g.svg';
export { default as closeIcon } from './assets/icons/close/close_32.svg';

export { default as festoLogo } from './assets/logo/festo/logo_festo.svg';

export { default as bmcPicture1 } from './assets/pictures/BMC_Simulator_01.jpg';
export { default as bmcPicture2 } from './assets/pictures/BMC_Simulator_03.jpg';
export { default as bmcPicture3 } from './assets/pictures/BMC_Simulator_04-alternative.jpg';
export { default as bmcPicture4 } from './assets/pictures/BMC_Simulator_05.jpg';
export { default as bmcPlaceholder1 } from './assets/pictures/BMC_Simulator_01_placeholder.jpg';
export { default as bmcPlaceholder2 } from './assets/pictures/BMC_Simulator_03_placeholder.jpg';
export { default as bmcPlaceholder3 } from './assets/pictures/BMC_Simulator_04-alternative_placeholder.jpg';
export { default as bmcPlaceholder4 } from './assets/pictures/BMC_Simulator_05_placeholder.jpg';

export { default as dataPrivacyPdf } from './files/Festo-Didactic-Privacy-Policy.pdf';
export { default as imprintPdf } from './files/Imprint-BMCSim.pdf';
