import React from 'react';
import './TermsOfUse.css';
import Header from '../../content/header/Header';
import { getTermsOfUsePdf } from '../../content/termsOfUse/TermsOfUsePdf';

export default function TermsOfUse() {

    return (
        <div className="terms-page">
            <header className="BMC-Header">
                <Header />
            </header>
            <div className="separator" />
            <iframe
                title="Basic Motor Control Technology Terms of Use"
                src={getTermsOfUsePdf() + "#view=FitV&toolbar=0"}
                className="terms-iframe"
            />
        </div>
    );
}