import RandomUtils from "./randomUtils";
import CryptoJS  from "crypto-js";

export class OpenIdUtils {
    static getAuthenticationData(openIdConfiguration, language) {
        let clientId = openIdConfiguration.clientId;
        let responseType = "code";
        let redirectUri = window.location.origin + "/" + language + "/login"; // window.location.origin + "/login";
        let scope = "openid profile email";
        let responseMode = "query";

        let codeVerifier = OpenIdUtils.generateCodeVerifier();
        let codeChallenge = OpenIdUtils.encodeCodeChallenge(codeVerifier);
        let codeChallengeMethod = "S256";

        return {
            authenticationUrl: openIdConfiguration.endpointAuthorizeUrl +
                `?client_id=${encodeURI(clientId)}` +
                `&response_type=${encodeURI(responseType)}` +
                `&redirect_uri=${encodeURI(redirectUri)}` +
                `&scope=${encodeURI(scope)}` +
                `&response_mode=${encodeURI(responseMode)}` +
                `&code_challenge=${encodeURI(codeChallenge)}` +
                `&code_challenge_method=${encodeURI(codeChallengeMethod)}`,
            codeVerifier: codeVerifier
        };
    }

    static getFestoOpenIdConfiguration() {
        return {
            clientId: process.env.REACT_APP_SSO_CLIENT_ID,
            endpointAuthorizeUrl: "https://login.microsoftonline.com/" + process.env.REACT_APP_SSO_TENANT_ID + "/oauth2/authorize",
        };
    }

    static generateCodeVerifier() {
        return btoa(RandomUtils.randomString(32))
            .split("+").join("-")
            .split("/").join("_")
            .split("=").join("");
    }

    static encodeCodeChallenge(codeVerifier) {
        let hash = CryptoJS.SHA256(codeVerifier);
        return CryptoJS.enc.Base64.stringify(hash)
            .split("+").join("-")
            .split("/").join("_")
            .split("=").join("");
    }

    static setCodeVerifier(codeVerifier) {
        localStorage.setItem("ssoCodeVerifier", codeVerifier);
    }

    static setClientId(clientId) {
        localStorage.setItem("ssoClientId", clientId);
    }

    static getCodeVerifier() {
        let codeVerifier = localStorage.getItem("ssoCodeVerifier") || "";

        return codeVerifier;
    }

    static getClientId() {
        let clientId = localStorage.getItem("ssoClientId") || "";

        return clientId;
    }

    static clearSsoInfos() {
        localStorage.removeItem("ssoCodeVerifier");
        localStorage.removeItem("ssoClientId");
    }
}