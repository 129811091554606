import './App.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import AuthProvider from "./provider/authProvider";
import Routes from "./index";
import { Link, useNavigate } from 'react-router-dom';
import CookiesPolicyConsentContext from './contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';
import TermsOfUseConsentContext from './contexts/termsOfUseConsent/withTermsOfUseConsentContext';
import { showTermsOfUseModal } from './content/termsOfUse/TermsOfUseModal';
import { showCookiesPolicyModal } from './content/cookiesPolicy/CookiesPolicyConsentModal';
import { refreshTokens, getLxTokens } from './utility/lxSessionUtils';


export default function App() {
    const [cookiesPolicyModalOpen, setCookiesPolicyModalOpen] = useState(getCookiesPolicyModalOpenInitialValue() ? showCookiesPolicyModal() : false);
    const [termsOfUseModalOpen, setTermsOfUseModalOpen] = useState(showTermsOfUseModal());

    useEffect(() => {
        setInterval(() => {
            let lxTokens = getLxTokens();

            if (lxTokens.lxRefreshToken && lxTokens.lxToken)
            {
                refreshTokens();
            }
        }, 1000 * 60 * 14) // Interval de 14 minutes (dur�e de vie minimum du token de lx : 15 minutes)
    }, []);

    function getCookiesPolicyModalOpenInitialValue() {
            return !window.location.pathname.includes('/cookies-policy');
    }

    function handleTermsOfUseModalClose() {
        setTermsOfUseModalOpen(false);
    }

    function handleCookiesPolicyModalOpen() {
        setCookiesPolicyModalOpen(true);
    }

    function handleCookiesPolicyModalClose() {
        setCookiesPolicyModalOpen(false);
    }

    return (
        <AuthProvider>
            <CookiesPolicyConsentContext.Provider value={{ cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose }}>
                <TermsOfUseConsentContext.Provider value={{ termsOfUseModalOpen, handleTermsOfUseModalClose }}>
                    <Routes />
                </TermsOfUseConsentContext.Provider>
            </CookiesPolicyConsentContext.Provider>
        </AuthProvider>
    );
}

export { Link, useNavigate };