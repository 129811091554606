import React from "react";
import "./InputRadio.css";

function InputRadio({ ...props }) {
    return (
        <div
            className={`input-radio`}
            key={props.id}
            style={props.style}
        >
            <div className="d-flex">
                <label
                    htmlFor={props.id}
                    className="radio-label"
                >
                    <input
                        type="radio"
                        name={props.groupName}
                        id={props.id}
                        value={props.id}
                        checked={props.checked}
                        disabled={props.disabled}
                        onChange={(event) =>
                            props.onChange && props.onChange(event.target.value)
                        }
                    />
                    <span className="radio-span-btn"></span>
                    {props.label}
                </label>
            </div>
        </div>
    );
}

export default InputRadio;