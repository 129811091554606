import { useState, useEffect } from "react";
import "./ProgressiveImage.css";

// Utilis� pour afficher une image temporaire en attendant le chargement de l'image
// L'image temporaire est une version de faible r�solution (2 � 8 kB) qui est charg�e plus rapidement
// Possible d'utiliser la fonction resize dans Paint pour r�duire le nombre de pixels
// S'assurer que l'image temporaire a le m�me ratio d'aspect de l'image originale pour conserver le layout de la page lors du chargement
const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    const customClass =
        placeholderSrc && imgSrc === placeholderSrc ? "image loading" : "image loaded";

    return (
        <img
            {...{ src: imgSrc, ...props }}
            alt={props.alt || ""}
            className={customClass}
        />
    );
};
export default ProgressiveImg;