import React from "react";
import "./InputRadioGroup.css";
import InputRadio from "./InputRadio";

function InputRadioGroup({ ...props }) {
    return (
        <div
            className={"input-radio-group"}
            key={props.id}
        >
            {props.values.map((inputRadio) => (
                <InputRadio
                    id={inputRadio.id}
                    key={inputRadio.id}
                    groupName={props.id}
                    label={inputRadio.label}
                    checked={inputRadio.value === props.value}
                    onChange={() => props.onChange(inputRadio.value)}
                    disabled={props.disabled}
                />
            ))}
        </div>
    );
}

export default InputRadioGroup;
